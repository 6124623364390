import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  memo,
  useCallback,
  useMemo,
} from 'react'
import { Drawer } from 'antd'
import * as _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import { useSelector } from 'react-redux'
import organizationActions from '../../../store/modules/organizationActions'
import messageActions from '../../../store/modules/messageActions'
import authActions from '../../../store/modules/authActions'
import userActions from '../../../store/modules/userActions'
import {
  idOrganizationSelector,
  isMobileAppSelector,
  menuOpenSelector,
  organizationSelector,
  selectedConversationSelector,
  userSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import {
  getFilteredCount,
  hasTrueOrNotEmptyValue,
  isToday,
  myWebViewPost,
  replaceWords,
  setMixpanel,
} from '../../../utils'
import SVGEmptyConversationMobile from '../../../icons/SVG/SVGEmptyConversationMobile'
import SVGArrowLeft from '../../../icons/SVG/SVGArrowLeft'
import utilDate from '../../../utils/utilsDate'
import { notifyError } from '../../../utils/Notify'
import { useSocket } from '../../../utils/socket'
import PushNotification from '../../../components/api/PushNotification'
import {
  MY_MESSAGES,
  ALL,
  ARCHIVED,
  UNASSIGNED,
  BROADCAST,
  RECEIVED,
  UNSUBSCRIBE,
  EMAIL,
  PHONE,
  SMS,
} from '../utils/constants'
import MessageCenter from '../center/MessageCenterMain/MessageCenter'
import MessageRight from '../right/MessageRightMain/MessageRight'
import { MULTIPLE_CONVERSATIONS_TYPE_OBJECT } from '../utils/messageUtils'
import MessageLeftPanel from '../left/MessageLeftPanel/MessageLeftPanel'
import './MessageMain.scss'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import useQueryParams from '../../../utils/useQueryParams'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import { CUSTOM } from '../../../devOptions'

const bsClassNamesArray = [
  '.message_center_panel-drawer',
  '.message-footer-small-ui',
  '.add-appointment-popover-overlay',
  '.mat-template-select-dropdown',
  '.message-payment-modal',
  '.message_date_picker_dropdown',
  '.message-invitation-modal',
  '.mobileBS',
  '.ant-tooltip',
  '.conversation_page_center_footer_mobile',
]

export const MessageContext = React.createContext({})
const PER_PAGE = 50

const MessageMain = memo(({ isExtension }) => {
  const { isTabletPortraitOrMobile, isMobile, isDesktop } = useDeviceCheck()
  const { setPageTitle } = useLayout()

  const history = useLocation()
  const navigate = useNavigate()
  const { type, cid, hash } = useQueryParams()

  const menuOpen = useSelector(menuOpenSelector)
  let organization = useSelector(organizationSelector)
  let organizationID = useSelector(idOrganizationSelector)
  const user = useSelector(userSelector)
  const isMobileApp = useSelector(isMobileAppSelector)
  const getConversationFromRedux = useSelector(selectedConversationSelector)

  const fliterObject = {
    fingerprinting: false,
    superhuman: false,
    incomingCalls: false,
    opportunities: (history.state && history.state.opportunities) || false,
    touchpoints: [],
    users: [],
    dateFilter:
      history.state && history.state.dashboardClickableStats
        ? history.state.dashboardClickableStats.dateFilter
          ? history.state.dashboardClickableStats.dateFilter.map((item) =>
              utilDate.getDayJSDate(item)
            )
          : []
        : [],
    tagsFilter: [],
    locationFilter: history?.state?.dashboardClickableStats?.locations
      ? [history.state.dashboardClickableStats.locations]
      : [],
    npsScoreFilter: '',
    broadcastFilter:
      (history.state && history.state.broadcast && history.state.broadcast) || '',
    fromBroadcastPage: Boolean(history.state && history.state.broadcast),
    campaign_responds: (history.state && history.state.reply) || false,
    dashboardClickableStats:
      (history.state && history.state.dashboardClickableStats) || false,
    filterOutgoingCalls: false,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [listConversation, setListConversation] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const [unreadOnly, setUnreadOnly] = useState(isExtension ? true : false)
  const [smartBox, setSmartBox] = useState(false)
  const [needsAttention, setNeedsAttention] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')

  const [page, setPage] = useState(0)
  const [messageType, setMessageType] = useState(
    user.isSupportAgent
      ? MY_MESSAGES
      : isExtension
        ? ALL
        : type
          ? type
          : (user.isSupportAgent &&
                organization?.restrictions
                  ?.restrictSupportAgentsAccessToMainInboxFolder) ||
              (history.state && history.state.conv)
            ? MY_MESSAGES
            : ALL
  )
  const [selectedConversation, setSelectedConversation] = useState(false)
  const [rightBoxVisible, setRightBoxVisible] = useState(false)
  const [centerBoxVisible, setCenterBoxVisible] = useState(false)
  const [msgChangeEmailSMSType, setMsgChangeEmailSMSType] = useState(PHONE)

  const [countOfFilter, setCountOfFilter] = useState(0)
  const [filterToggleStatuses, setFilterToggleStatuses] = useState(fliterObject)
  const [selectMultipleConversationsType, setselectMultipleConversationsType] =
    useState(MULTIPLE_CONVERSATIONS_TYPE_OBJECT)

  const messageDataFromAppPage = history.state && history.state.messageFromWebView

  const scrollConversation = useRef()
  const listRef = useRef()
  const MessageCenterRef = useRef()
  const mixpanel = useMixpanel()
  const messageInputRef = useRef()
  const searchBoxRef = useRef()
  const isFirstRender = useRef(true)

  if (organization && !Object.keys(organization).length) {
    organizationActions.getSingle(user._organization_id).then((response) => {
      organization = response.data
    })
  }

  const isFilteredMessage = hasTrueOrNotEmptyValue(filterToggleStatuses)

  useEffect(() => {
    !isExtension && setPageTitle(getText('TITLE_MESSAGES'))
  }, [])

  useEffect(() => {
    if (isExtension && !isTabletPortraitOrMobile) {
      setCenterBoxVisible(false)
    }
  }, [])

  useLayoutEffect(() => {
    if (history.state) {
      if (history.state.broadcast || history.state.dashboardClickableStats) {
        authActions.setMenuOpen(false)
        history.state = null
      }
    }
  }, [])

  useEffect(() => {
    if (messageDataFromAppPage) {
      getConversationFromNotification(messageDataFromAppPage)
      history.state = null
    }
  }, [messageDataFromAppPage])

  useEffect(() => {
    if (user.isSupportAgent) {
      const isMainInboxWithoutAccess =
        messageType === ALL &&
        !organization?.restrictions?.allowSupportAgentsAccessToMainInboxFolder
      const isUnassignedWithoutAccess =
        messageType === UNASSIGNED &&
        !organization?.restrictions?.allowSupportAgentsAccessToUnassignedFolder
      if (
        isMainInboxWithoutAccess ||
        isUnassignedWithoutAccess ||
        messageType === BROADCAST
      ) {
        navigate('/error')
        return
      }
    }

    navigate(`?type=${messageType}`, { state: history.state })
  }, [messageType])

  const backswipeAction = (
    className,
    number,
    postMsg,
    actionOfVisibalities,
    actionMsg,
    customBehave = false
  ) => {
    if (!Array.isArray(className)) {
      className = [className]
    }

    for (const classItem of className) {
      const item = document.querySelector(classItem)
      if (item && window.platform === 'ios') {
        item.style.opacity = number
      }
    }
    if (customBehave) {
      document.body.style.overflow = ''
      messageInputRef && messageInputRef.current && messageInputRef.current.blur()
    }
    if (postMsg !== null) {
      myWebViewPost(
        {
          shouldBackswipe: postMsg,
        },
        'SHOW_MESSAGE_CENTER'
      )
    }
    actionOfVisibalities && actionOfVisibalities(actionMsg)
  }

  const messageFromWebView = async (e) => {
    let comingData = e.data
    if (Boolean(comingData)) {
      try {
        comingData = JSON.parse(comingData)
        if (comingData.type === 'backswipe') {
          const data = comingData.data
          if (data === 'center') {
            backswipeAction(
              bsClassNamesArray,
              0,
              false,
              setCenterBoxVisible,
              false,
              true
            )
          } else if (data === 'right') {
            backswipeAction(
              '.message_right_panel-drawer',
              0,
              'center',
              setRightBoxVisible,
              false
            )
          }
        } else if (comingData.type === 'fcmData') {
          const data = comingData
          delete data.type
          await userActions.sendFCMToken(data)
        } else if (comingData.type === 'notification') {
          getConversationFromNotification(comingData)
        } else if (comingData.type === 'REDIRECT_FROM_SETTINGS') {
          navigate('/profile/notification')
        }
      } catch (error) {
        console.error('Invalid JSON data:', error, comingData)
      }
    }
  }
  useEffect(() => {
    if (window.platform === 'android') {
      document.addEventListener('message', messageFromWebView)
    } else if (window.platform === 'ios') {
      window.addEventListener('message', messageFromWebView)
    }
    return () => {
      if (window.platform === 'android') {
        document.removeEventListener('message', messageFromWebView)
      } else if (window.platform === 'ios') {
        window.removeEventListener('message', messageFromWebView)
      }
    }
  }, [])

  useEffect(() => {
    if (isMobileApp && history.state && !history.state.centerDrawerFalse) {
      document.body.style.overflow = ''
      setCenterBoxVisible(false)
      setSelectedConversation(false)
      history.state = null
    }
  }, [history.state])

  const checkConversationContainInTheList = (sources) => {
    if (!filterToggleStatuses.touchpoints.length) return true

    return filterToggleStatuses.touchpoints.some((ele) =>
      sources.some((item) => item.source === ele.source)
    )
  }

  const markAsReadAction = ({ isConversationSelected, data }) => {
    if (isConversationSelected) {
      return refreshConversation(data.conversation, false, false)
    } else {
      return refreshConfersationList(data.conversation)
    }
  }

  const resetFilters = (hardReset) => {
    setFilterToggleStatuses(hardReset ? { ...fliterObject } : fliterObject)
    listRef.current.resetFilters()
  }

  const onReceiveMessage = (event, data) => {
    if (!data || !data.conversation) return
    if (user._organization_id !== data._organization_id) return

    const statusColorToText = (color = '') => {
      switch (color) {
        case 'red':
          return getText('WORD_REFUSED')
        case 'yellow':
          return getText('WORD_PENDING')
        case 'green':
          return getText('WORD_COMPLIANT')
        default:
          return color
      }
    }
    const isConversationSelected =
      selectedConversation && selectedConversation._id === data.conversation._id
    const isUserInConversation = data.conversation.users.includes(user?._id)

    switch (messageType) {
      case MY_MESSAGES:
        if (
          event === 'UNASSIGN_CONVERSATION_USER' &&
          !isUserInConversation &&
          isConversationSelected
        ) {
          return removeConversationFromList(data.conversation)
        }
        if (event === 'MARK_READ_CONVERSATION') {
          return markAsReadAction({ isConversationSelected: false, data })
        }
        if (event === 'ARCHIVE_CONVERSATION') {
          const isEmail = data.conversation.receiver.email
          if (isEmail) {
            return
          }
          if (isUserInConversation && isConversationSelected) {
            return removeConversationFromList(data.conversation, true, true)
          }
          return removeConversationFromList(data.conversation, false)
        }
        break
      case ARCHIVED:
        if (event === 'UNARCHIVE_CONVERSATION') {
          removeConversationFromList(data.conversation, true, true)
          return
        }
        if (event === 'CONVERSATION_REMINDER_CREATED') {
          markAsReadAction()
        }
        break
      case ALL:
        if (event === 'MARK_READ_CONVERSATION') {
          markAsReadAction({ isConversationSelected, data })
        }
        if (event === 'UNARCHIVE_CONVERSATION' && !isUserInConversation) {
          if (Boolean(filterToggleStatuses.fromBroadcastPage)) {
            return
          }
          return reorderToTop(data.conversation)
        }
        if (event === 'ARCHIVE_CONVERSATION') {
          removeConversationFromList(data.conversation, true, true)
          return
        }
        if (event === 'CONSENT_CHANGED' && !isUserInConversation) {
          const previousStatus = statusColorToText(
            data.previousStatus || data?._doc?.data?.previousStatus
          )
          const newStatus = statusColorToText(
            data.newStatus || data?._doc?.data?.newStatus
          )
          const obj = {
            isManual: data.isManual || data?._doc?.data?.isManual,
            message: replaceWords(getText('MESSAGE_CONSENT_CHANGED'), {
              user: data.whoChangeConsent || data?._doc?.data?.whoChangeConsent,
              previousStatus: previousStatus,
              newStatus: newStatus,
            }),
            newStatus: data.newStatus || data?._doc?.data?.newStatus,
            previousStatus: data.previousStatus || data?._doc?.data?.previousStatus,
            createdAt: data.timestamp,
            type: 'consent_edit',
            _conversation_id: data.conversation._id,
            whoChangeConsent:
              data.whoChangeConsent || data?._doc?.data?.whoChangeConsent,
          }

          if (data.conversation.is_archived) {
            return
          }

          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(obj)
          }
          if (isConversationSelected) {
            return refreshConversation(data.conversation, false)
          } else {
            return reorderToTop(data.conversation)
          }
        }
        break
      case UNASSIGNED:
        if (event === 'MARK_READ_CONVERSATION') {
          markAsReadAction({ isConversationSelected, data })
        }
        if (event === 'UNASSIGN_CONVERSATION_USER' && data.conversation.unassigned) {
          return
        }
        if (
          (event === 'UNASSIGN_CONVERSATION_USER' && !isUserInConversation) ||
          (event === 'ARCHIVE_CONVERSATION' && isUserInConversation)
        ) {
          return removeConversationFromList(data.conversation)
        }
        if (event === 'ASSIGN_CONVERSATION_USER') {
          return refreshConversation(data.conversation, false)
        }
        if (event === 'CONVERSATION_REMINDER_CREATED' && isConversationSelected) {
          if (isUserInConversation) {
            if (user.isSupportAgent) {
              return setMessageType(MY_MESSAGES)
            } else {
              return setMessageType(ALL)
            }
          } else {
            return removeConversationFromList(data.conversation)
          }
        }
        break
      default:
        break
    }

    if (isConversationSelected && event === 'CUSTOMER_STATUS_WAS_CHANGED') {
      return refreshConversation(data.conversation, false)
    }

    if (isConversationSelected && event === 'UPDATE_CONVERSATION') {
      return refreshConversation(data.conversation, false)
    }

    if (isConversationSelected && event === 'PHONE_STATUS_CHANGED') {
      return refreshConversation(data.conversation, false)
    }

    if (isConversationSelected && event === 'CONVERSATION_INTENTS_UPDATED') {
      return refreshConversation(data.conversation, false)
    }

    if (event === 'ARCHIVE_CONVERSATION') {
      const isEmail = data.conversation.receiver.email
      if (isEmail) {
        return
      }
      return removeConversationFromList(data.conversation, true, true)
    }

    if (
      isConversationSelected &&
      (event === 'SCHEDULED_MESSAGE_UPDATED' ||
        event === 'SCHEDULED_MESSAGE_CREATED')
    ) {
      return refreshConversation(data.conversation, true)
    }

    if (isConversationSelected && event === 'UPDATE_CUSTOMER') {
      return refreshConversation(data.conversation, false)
    }

    if (event === 'NEW_NOTE_APPEAR') {
      if (isConversationSelected) {
        data.conversation.messages = selectedConversation.messages
        data.conversation.sources_names = selectedConversation.sources_names
        refreshConversation(data.conversation, false)
        data.note._conversation_id = data.conversation._id
        data.note.type = 'note'
        data.note.updatedAt = data.note.createdAt
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(data.note)
        }
        return
      }
      return
    }

    if (isConversationSelected && event === 'SCHEDULED_MESSAGE_REMOVED') {
      data.newInsertedMsg.isScheduled = true
      MessageCenterRef.current.removeScheduledMessage()
      return refreshConversation(data.conversation, true)
    }

    if (
      isConversationSelected &&
      ['APPOINTMENT_CREATED', 'APPOINTMENT_UPDATED', 'APPOINTMENT_REMOVED'].includes(
        event
      )
    ) {
      data.appointment.isAppointment = true
      data.appointment._conversation_id = data.conversation._id
      MessageCenterRef?.current?.addNewMessage(data.appointment)
      return refreshConversation(data.conversation, true)
    }

    if (
      event === 'CONVERSATION_REMINDER_CREATED' &&
      isUserInConversation &&
      isConversationSelected
    ) {
      return refreshConversation(data.conversation, true)
    }
    if (
      event === 'CONVERSATION_REMINDER_CANCELLED' &&
      isUserInConversation &&
      isConversationSelected
    ) {
      MessageCenterRef.current.removeRemindedMessage()
      refreshConversation(data.conversation, false)
      return
    }

    if (event === 'CONVERSATION_REMINDER_NUDGED' && isUserInConversation) {
      if (isConversationSelected) {
        return refreshConversation(data.conversation, true)
      } else {
        return reorderToTop(data.conversation)
      }
    }

    if (
      [MY_MESSAGES, ALL, ARCHIVED, UNASSIGNED].includes(messageType) &&
      !isUserInConversation
    )
      return

    if (isConversationSelected) {
      if (data.conversation.is_archived) {
        return
      }
      if (event === 'CONSENT_CHANGED') {
        const previousStatus = statusColorToText(
          data.previousStatus || data?._doc?.data?.previousStatus
        )
        const newStatus = statusColorToText(
          data.newStatus || data?._doc?.data?.newStatus
        )
        if (data.newStatus === 'red' && data.conversation.receiver.email) {
          setMsgChangeEmailSMSType(EMAIL)
        }
        const obj = {
          isManual: data.isManual || data?._doc?.data?.isManual,
          message: replaceWords(getText('MESSAGE_CONSENT_CHANGED'), {
            user: data.whoChangeConsent || data?._doc?.data?.whoChangeConsent,
            previousStatus: previousStatus,
            newStatus: newStatus,
          }),
          newStatus: data.newStatus || data?._doc?.data?.newStatus,
          previousStatus: data.previousStatus || data?._doc?.data?.previousStatus,
          createdAt: data.timestamp,
          type: 'consent_edit',
          _conversation_id: data.conversation._id,
          whoChangeConsent:
            data.whoChangeConsent || data?._doc?.data?.whoChangeConsent,
        }

        refreshConversation(data.conversation, false)
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(obj)
        }
      } else {
        return refreshConversation(data.conversation, true)
      }
    } else {
      reorderToTop(data.conversation)
    }
  }

  const onReceiveMessageNew = (event, data) => {
    if (data.hasOwnProperty('newInsertedMsg')) {
      if (!data.newInsertedMsg.createdAt) {
        data.newInsertedMsg.createdAt = data.timestamp
      }
      if (data.hasOwnProperty('_conversation_id')) {
        if (!data.newInsertedMsg._conversation_id) {
          data.newInsertedMsg._conversation_id = data._conversation_id
        }
      }
    }

    if (!isMobileApp) {
      if (data.newInsertedMsg && data.newInsertedMsg.type === RECEIVED) {
        PushNotification(data)
      }
    }
    if (!data || !data.conversation) return
    if (user._organization_id !== data._organization_id) return

    let isToMe = false
    switch (messageType) {
      case ALL:
      case ARCHIVED:
        isToMe = true
        break
      case MY_MESSAGES:
        isToMe = data.conversation.users.includes(user?._id)
        break
      case UNASSIGNED:
        isToMe = smartBox
          ? !data.newInsertedMsg.is_lead
          : !data.conversation.unassigned || data.conversation.users.length === 0
        break
      default:
        break
    }
    const isConversationInList = checkConversationContainInTheList(
      data.conversation.sources_names || []
    )

    if (!isConversationInList || !isToMe) return
    if (data.conversation.hasOwnProperty('changed')) {
      data.conversation = Object.assign(selectedConversation, {
        invitation_items: data.conversation.invitation_items,
      })
    }
    if (messageType === ARCHIVED) {
      return removeConversationFromList(data.conversation, false)
    }

    if (!unreadOnly || (data.conversation && data.conversation.is_unread)) {
      if (isFilteredMessage || searchKeyword || needsAttention) {
        listConversation.map((item) => {
          if (item._id === data._conversation_id) {
            reorderToTop(data.conversation)
          }
          return item
        })
      } else {
        if (messageType !== BROADCAST && data.conversation.is_broadcast) {
          return
        }
        if (messageType === UNASSIGNED && !data.conversation.unassigned) {
          return
        }
        reorderToTop(data.conversation)
      }
    }

    const isConversationSelected =
      selectedConversation && selectedConversation._id === data.conversation._id

    if (
      isConversationSelected ||
      (selectedConversation &&
        data.conversation.receiver_phone === selectedConversation.receiver_phone)
    ) {
      if (event === 'NEW_INVITATION_AVAILABLE') {
        data.newInsertedInv.isInvitation = true
        data.newInsertedInv._conversation_id = data.conversation._id
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(data.newInsertedInv)
        }
        refreshConfersationList(data.conversation, true)
      } else if (event === 'INVITATION_WAS_CHANGED') {
        let invitation
        if (data.conversation.hasOwnProperty('changed')) {
          invitation = data.conversation.invitation_items[0]
        } else if (data.hasOwnProperty('invitation')) {
          invitation = data.invitation
        } else {
          invitation = data.conversation.invitation_items[0]
        }
        MessageCenterRef.current.updateInvitation(invitation)
      } else if (event === 'MESSAGE_STATUS_WAS_CHANGED') {
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.updateSmsStatus(
            data.newInsertedMsg.smsStatus,
            data.newInsertedMsg.id
          )
        }
      } else if (event === 'VIDEO_MESSAGE_ADDED') {
        data.video._conversation_id = data.conversation._id
        data.video.video = true
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(data.video)
        }
      } else if (event === 'VIDEO_MESSAGE_CHANGED') {
        if (data.video.status === 'VIDEO_PROCESSING_FAILED') {
          notifyError(
            getText('MSG_VIDEO_PROCESSING_FAILED_IN_ORDER_TO_RETRY_UPLOADING_IT')
          )
        }
        data.video._conversation_id = data.conversation._id
        data.video.video = true
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(data.video)
        }
      } else if (event === 'NEW_CONVERSATION_SUMMARY') {
        data.conversation.summaries_items[0].isSummaries = true
        data.conversation.summaries_items[0].createdAt =
          data.conversation.summaries_items[0].updatedAt
        data.conversation.summaries_items[0]._conversation_id = data._conversation_id
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(
            data.conversation.summaries_items[0]
          )
        }
      } else if (event === 'NEXT_SCHEDULED_MESSAGES_UPDATED') {
        let list = []
        if (Array.isArray(data.nextScheduledMessages.dripSequence)) {
          list = list.concat(data.nextScheduledMessages.dripSequence)
        }
        if (data.nextScheduledMessages.superhuman) {
          list.push(data.nextScheduledMessages.superhuman)
        }
        if (data.nextScheduledMessages.followUp) {
          data.nextScheduledMessages.followUp.type =
            data.nextScheduledMessages.followUp.status
          list.push(data.nextScheduledMessages.followUp)
        }
        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNextScheduledMessage(list)
        }
      } else if (event === 'NEW_MESSAGE_AVAILABLE') {
        if (messageType === UNASSIGNED) {
          data.conversation.unassigned = selectedConversation.unassigned
        }

        if (
          data.newInsertedMsg.type === UNSUBSCRIBE &&
          msgChangeEmailSMSType === EMAIL &&
          data.conversation.receiver.email
        ) {
          setMsgChangeEmailSMSType(PHONE)
        } else if (
          data.newInsertedMsg.sentBy === EMAIL &&
          data.conversation.receiver.email &&
          msgChangeEmailSMSType === PHONE
        ) {
          setMsgChangeEmailSMSType(EMAIL)
        } else if (
          data.newInsertedMsg.sentBy === SMS &&
          msgChangeEmailSMSType === EMAIL
        ) {
          setMsgChangeEmailSMSType(PHONE)
        }

        if (MessageCenterRef && MessageCenterRef.current) {
          MessageCenterRef.current.addNewMessage(data.newInsertedMsg)
        }
        if (data.newInsertedMsg.type === RECEIVED) {
          if (mixpanel.config && mixpanel.config.token) {
            data.newInsertedMsg.source_name
              ? setMixpanel(
                  mixpanel,
                  'Inbound leads',
                  user.createdAt,
                  user.fullName,
                  user.organization.name,
                  user.location.name,
                  data.newInsertedMsg.source_name,
                  user.organization?._id,
                  user.location?._id,
                  user?._id
                )
              : setMixpanel(
                  mixpanel,
                  'Inbound Message',
                  user.createdAt,
                  user.fullName,
                  user.organization.name,
                  user.location.name,
                  null,
                  user.organization?._id,
                  user.location?._id,
                  user?._id
                )
          }
        }
      }
      setSelectedConversation(data.conversation)
    }
  }

  const onReciveAllConversation = (event, data) => {
    if (!data) return
    if (user._organization_id !== data._organization_id) return
    if (event === 'MARK_READ_ARRAY_CONVERSATIONS') {
      return markAsReadConversationFromList(data.conversationIds)
    } else {
      return removeAllConversationFromList(data.conversationIds)
    }
  }

  const onReciveTimelineConversation = (data) => {
    if (user._organization_id !== data._organization_id) return
    setSelectedConversation((oldItem) => {
      if (oldItem._id === data._conversation_id) {
        oldItem.conversation_timeline_steps_items =
          data.conversation_timeline_steps_items
      }
      return oldItem
    })
  }

  const onReceiveAIReplyMessage = (event, data) => {
    if (user._organization_id !== data._organization_id) return
    if (event === 'SUPERHUMAN_REPLY_ACTION') {
      setListConversation((ov) => {
        const newList = ov.map((conv) => {
          if (conv._id === data._conversation_id) {
            return { ...conv, superhumanReply: data.superhumanReply }
          }
          return conv
        })
        return [...newList]
      })
    }
  }

  const handleNewConversation = (conv, sortedConversations) => {
    const filteredConversations = sortedConversations.filter(
      (conversation) => conversation._id !== conv._id
    )
    sortedConversations = [conv, ...filteredConversations]
    setListConversation(sortedConversations)
    setSelectedConversation(conv)
    saveSelectedConversation(conv)
    setCenterBoxVisible(true)
  }

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    ['NEW_INVITATION_AVAILABLE', 'VIDEO_MESSAGE_ADDED', 'NEW_MESSAGE_AVAILABLE'],
    onReceiveMessageNew,
    !!organizationID
  )

  useSocket(
    `private-organization=${organizationID}-conversation=${selectedConversation._id}`,
    [
      'INVITATION_WAS_CHANGED',
      'MESSAGE_STATUS_WAS_CHANGED',
      'VIDEO_MESSAGE_CHANGED',
      'NEW_CONVERSATION_SUMMARY',
      'NEXT_SCHEDULED_MESSAGES_UPDATED',
    ],
    onReceiveMessageNew,
    !!organizationID && !!selectedConversation._id
  )

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    [
      'MARK_READ_CONVERSATION',
      'ARCHIVE_CONVERSATION',
      'UNARCHIVE_CONVERSATION',
      'CONVERSATION_REMINDER_CREATED',
      'CONVERSATION_REMINDER_CANCELLED',
      'CONVERSATION_REMINDER_NUDGED',
    ],
    onReceiveMessage,
    !!organizationID
  )

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    ['SUPERHUMAN_REPLY_ACTION'],
    onReceiveAIReplyMessage,
    !!organizationID
  )

  useSocket(
    `private-organization=${organizationID}-conversation=${selectedConversation._id}`,
    [
      'ASSIGN_CONVERSATION_USER',
      'UNASSIGN_CONVERSATION_USER',
      'UPDATE_CUSTOMER',
      'NEW_NOTE_APPEAR',
      'CONSENT_CHANGED',
      'PHONE_STATUS_CHANGED',
      'UPDATE_CONVERSATION',
      'SCHEDULED_MESSAGE_CREATED',
      'SCHEDULED_MESSAGE_UPDATED',
      'SCHEDULED_MESSAGE_REMOVED',
      'CONVERSATION_INTENTS_UPDATED',
      'CUSTOMER_STATUS_WAS_CHANGED',
    ],
    onReceiveMessage,
    !!organizationID && !!selectedConversation._id
  )
  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    [
      'ARCHIVE_ARRAY_CONVERSATIONS',
      'MARK_READ_ARRAY_CONVERSATIONS',
      'UNARCHIVE_ARRAY_CONVERSATIONS',
    ],
    onReciveAllConversation,
    !!organizationID
  )

  useSocket(
    `private-organization=${organizationID}`,
    ['APPOINTMENT_CREATED', 'APPOINTMENT_UPDATED', 'APPOINTMENT_REMOVED'],
    onReceiveMessage,
    !!organizationID
  )

  const { onConnected } = useSocket(
    `private-organization=${organizationID}-conversation=${selectedConversation._id}`,
    'CONVERSATION_TIMELINE_UPDATED',
    onReciveTimelineConversation,
    !!organizationID && !!selectedConversation._id
  )

  useEffect(() => {
    const cleanup = onConnected(() => {
      if (!isLoading) {
        fill(listConversation, 0)
        MessageCenterRef.current.refreshMessage()
      }
    })
    return cleanup
  }, [onConnected, listConversation, isLoading])

  const shouldStayAtSamePlace = (conv) => {
    if (conv.messages && conv.messages.length > 0) {
      const latestMessage = conv.messages[0]
      return latestMessage.type === 'assign'
    }
  }

  const reorderToTop = (conv) => {
    if (shouldStayAtSamePlace(conv)) return

    let oldConv

    setListConversation((oldList) => {
      const list = oldList.filter((element) => {
        if (element._id === conv._id) {
          oldConv = element
        }

        return element._id !== conv._id && !element.isNew
      })
      const newConv = conv

      if (oldConv && oldConv.superhumanReply && !newConv.superhumanReply) {
        newConv.superhumanReply = oldConv.superhumanReply
      }

      const newList = [newConv, ...sortingConversation(list)]
      return newList
    })
  }

  const saveSelectedConversation = (conversation = false) => {
    const selectedConversationParsed = JSON.parse(
      localStorage.getItem('selectedConversation')
    )
    let index = (selectedConversationParsed && selectedConversationParsed.index) || 0

    const convToSave = conversation ? conversation : { index, conversation }

    window.localStorage.setItem('selectedConversation', JSON.stringify(convToSave))
  }

  const {
    fromBroadcastPage,
    opportunities,
    users,
    dateFilter,
    dashboardClickableStats,
    broadcastFilter,
    tagsFilter,
    touchpoints,
    locationFilter,
    npsScoreFilter,
    fingerprinting,
    incomingCalls,
    superhuman,
    campaign_responds,
    filterOutgoingCalls,
  } = filterToggleStatuses

  const paginationOptionsForAPI = {
    unreadOnly,
    onlyLastMessage: true,
    repliedBack: smartBox,
    needsAttention,
    ...(fromBroadcastPage || opportunities ? {} : { type: messageType }),
    topConversationId: cid || '',
    ...(users.length && { users: users.join(';') }),
    ...(dateFilter[0] && {
      ...(dashboardClickableStats?.range === CUSTOM
        ? { start_date: utilDate.getCurrentDateByDayJS(dateFilter[0], 'YYYY-MM-DD') }
        : { date_gte: utilDate.getCurrentDateByDayJS(dateFilter[0], 'YYYY-MM-DD') }),
    }),
    ...(dateFilter[1] && {
      ...(dashboardClickableStats?.range === CUSTOM
        ? { end_date: utilDate.getCurrentDateByDayJS(dateFilter[1], 'YYYY-MM-DD') }
        : { date_lte: utilDate.getCurrentDateByDayJS(dateFilter[1], 'YYYY-MM-DD') }),
    }),
    ...(dashboardClickableStats?.range &&
      dashboardClickableStats.range !== CUSTOM && {
        range: dashboardClickableStats.range,
      }),
    ...(broadcastFilter && { campaigns: broadcastFilter._id }),
    ...(tagsFilter.length && { tags: tagsFilter.map((tag) => tag._id).join(';') }),
    ...(touchpoints.length && {
      touchpoints: touchpoints.map((oo) => oo._id).join(','),
    }),
    ...(locationFilter.length &&
      !dashboardClickableStats?.locations && {
        locations: locationFilter.join(';'),
      }),
    ...(npsScoreFilter && { npsScore: npsScoreFilter }),
    ...(fingerprinting && { onlyActivitySequence: fingerprinting }),
    ...(incomingCalls && { filterInboundCalls: incomingCalls }),
    ...(superhuman && { superhumanAction: superhuman }),
    ...(opportunities && { campaign_opportunities: opportunities }),
    ...(campaign_responds && !opportunities && { campaign_responds }),
    ...(dashboardClickableStats?.superhuman_influenced_walkins && {
      superhuman_influenced_walkins:
        dashboardClickableStats.superhuman_influenced_walkins,
    }),
    ...(dashboardClickableStats?.influenced_walkins && {
      influenced_walkins: dashboardClickableStats.influenced_walkins,
    }),
    ...(dashboardClickableStats?.superhuman_assisted_handoffs && {
      superhuman_assisted_handoffs:
        dashboardClickableStats.superhuman_assisted_handoffs,
    }),
    ...(dashboardClickableStats?.superhuman_appointments && {
      superhuman_appointments: dashboardClickableStats.superhuman_appointments,
    }),
    ...(dashboardClickableStats?.superhuman_appointments_type && {
      superhuman_appointments_type:
        dashboardClickableStats.superhuman_appointments_type,
    }),
    ...(locationFilter.length &&
      dashboardClickableStats?.locations && {
        _location_id: Array.isArray(locationFilter)
          ? locationFilter.join('')
          : locationFilter,
      }),
    ...(filterOutgoingCalls && { filterOutgoingCalls }),
  }

  const fill = useCallback(
    async (listData = [], nextPage = page, activeConversation = null) => {
      setIsLoading(true)
      const combinedArray = getFilteredCount(filterToggleStatuses)

      setCountOfFilter(combinedArray.length)

      const result = await messageActions.getPageList(
        nextPage,
        PER_PAGE,
        searchKeyword,
        paginationOptionsForAPI
      )

      if (result.success) {
        if (window.history.state && window.history.state.changeMessageType) {
          if (!result.max) {
            if (user.isSupportAgent) {
              setMessageType(MY_MESSAGES)
            } else {
              setMessageType(ALL)
            }
          }
          window.history.pushState(null, null)
        }

        let sortedConversations = sortingConversation(result.data)

        if (nextPage !== 0) {
          sortedConversations = listData.concat(sortedConversations)
          sortedConversations = _.uniqBy(sortedConversations, (e) => e._id)
        }
        if (!sortedConversations.length) {
          setSelectedConversation(false)
          saveSelectedConversation(false)
        }
        const newConvParsed = JSON.parse(
          localStorage.getItem('createNewConversationFromURL')
        )

        const newConvFromAppointment = history.state

        if (newConvFromAppointment) {
          navigate('/messages', {
            replace: true,
          })
        }

        const newConvFromElead = hash

        if (getConversationFromRedux) {
          handleNewConversation(getConversationFromRedux, sortedConversations)
          authActions.setConversation(false)
        } else if (newConvParsed) {
          handleNewConversation(newConvParsed, sortedConversations)
          localStorage.removeItem('createNewConversationFromURL')
        } else if (
          (newConvFromAppointment && newConvFromAppointment.conv) ||
          newConvFromElead
        ) {
          let result
          let newConv
          if (newConvFromElead) {
            result = await messageActions.getEleadConversation(newConvFromElead)
          } else {
            result = await messageActions.getConversationById(
              newConvFromAppointment.conv._id
            )
          }
          if (result.success) {
            newConv = result.data.conversation[0]
            handleNewConversation(newConv, sortedConversations)
          } else {
            notifyError(getText('WORD_CONVERSATION_NOT_FOUND'))
          }
        } else {
          const savedConversation = JSON.parse(
            localStorage.getItem('selectedConversation')
          )
          const conversation =
            activeConversation ||
            (savedConversation ? savedConversation.conversation : null)
          const isContainItem = sortedConversations.some((item) => {
            if (conversation && conversation._id) {
              return item._id === conversation._id
            }
            return false
          })
          if (isContainItem) {
            if (cid) {
              setSelectedConversation(sortedConversations[0])
              saveSelectedConversation(sortedConversations[0])
            } else {
              if (nextPage === 0)
                finedScrollToTop((savedConversation && savedConversation.index) || 0)
              if (!isMobileApp) {
                setSelectedConversation(conversation)
              }
            }
          } else {
            if (!isMobileApp) {
              setSelectedConversation(sortedConversations[0] || false)
              saveSelectedConversation(sortedConversations[0] || false)
            }
          }
          setListConversation(sortedConversations)
        }
        setTotalCount(result.max)
        setPage(nextPage)
      } else {
        setSelectedConversation(false)
        saveSelectedConversation(false)
        setListConversation([])
        setTotalCount(0)
      }
      authActions.setLoadingOrganization(false)
      setIsLoading(false)
    },
    [
      searchKeyword,
      messageType,
      unreadOnly,
      smartBox,
      needsAttention,
      filterToggleStatuses,
    ]
  )

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    resetFilters(true)
    setUnreadOnly(isExtension ? true : false)
    setSmartBox(false)
    setNeedsAttention(false)
    setSearchKeyword('')
  }, [organizationID])

  useEffect(() => {
    scrollToTop()
    fill(listConversation, 0)
  }, [fill])

  useEffect(() => {
    if (selectMultipleConversationsType.selectAll) {
      const selectedAllConversation = listConversation
        .filter(
          (conversation) =>
            !selectMultipleConversationsType.exceptConversationsIds.includes(
              conversation._id
            )
        )
        .map((conversation) => conversation._id)
      setselectMultipleConversationsType({
        ...selectMultipleConversationsType,
        unreadIds: selectedAllConversation,
        archiveConversationsIds: selectedAllConversation,
        unArchiveConversationIds: selectedAllConversation,
      })
    }
  }, [listConversation])

  const filterConversationsBySearchTerm = useCallback(
    (data) => {
      if (!searchKeyword) return data
      const searchTerm = searchKeyword.toLowerCase()

      return data.filter((x) => {
        if (x.receiver) {
          ;(x.receiver.phone &&
            x.receiver.phone.toLowerCase().includes(searchTerm)) ||
            (x.receiver.firstName &&
              x.receiver.firstName.toLowerCase().includes(searchTerm)) ||
            (x.receiver.lastName &&
              x.receiver.lastName.toLowerCase().includes(searchTerm)) ||
            (x.receiver.fullName &&
              x.receiver.fullName.toLowerCase().includes(searchTerm)) ||
            (x.receiver.email && x.receiver.email.toLowerCase().includes(searchTerm))
        }
        return x
      })
    },
    [listConversation, searchKeyword, messageType]
  )

  const sortingConversation = (data) => {
    if (messageType !== MY_MESSAGES) return data
    const list = filterConversationsBySearchTerm(data)
    const conditionForSorting = (obj) =>
      obj.smart_nudge_enabled &&
      obj.preferred_nudge_time &&
      isToday(obj.preferred_nudge_time)
    return [...list].sort(
      (convA, convB) => conditionForSorting(convB) - conditionForSorting(convA)
    )
  }

  const changeToNextAvailableConversation = (list, conv, selectedConv) => {
    let currentIndex
    if (conv._id !== selectedConv._id) {
      return
    } else {
      currentIndex = list.findIndex((x) => x.id === conv.id)
    }
    if (list && list.length > 1) {
      const nextIndex = currentIndex + 1
      const previousIndex = currentIndex - 1

      if (list[nextIndex]) {
        setSelectedConversation(list[nextIndex])
        return saveSelectedConversation(list[nextIndex])
      }

      if (list[previousIndex]) {
        setSelectedConversation(list[previousIndex])
        return saveSelectedConversation(list[previousIndex])
      }
    } else {
      if (messageType === ARCHIVED) {
        setSelectedConversation(false)
        saveSelectedConversation(false)
      } else {
        resetFilters()
        setSearchKeyword('')
        searchBoxRef?.current?.editSearchValue('')
      }
    }

    if (user.isSupportAgent) {
      setMessageType(MY_MESSAGES)
    } else {
      setMessageType(ALL)
    }
  }

  const removeConversationFromList = (
    conv,
    changeSelectedConversation = true,
    changeToNextAvailable = false,
    fromUnssign = false
  ) => {
    const list = listConversation.filter(
      (element) => element._id !== conv._id && !element.isNew
    )
    if (changeSelectedConversation) {
      if (changeToNextAvailable) {
        changeToNextAvailableConversation(
          listConversation,
          conv,
          selectedConversation
        )
      } else {
        setSelectedConversation(fromUnssign ? conv : list.length ? list[0] : false)
        saveSelectedConversation(fromUnssign ? conv : list.length ? list[0] : false)
      }
    }
    setListConversation(list)
  }

  const removeAllConversationFromList = (
    convList,
    changeSelectedConversation = true
  ) => {
    const list = listConversation.filter(
      (element) => !convList.includes(element._id)
    )
    if (changeSelectedConversation) {
      setSelectedConversation(list.length ? list[0] : false)
      saveSelectedConversation(list.length ? list[0] : false)
    }
    setListConversation(list)
    if (!list.length) {
      if (isFilteredMessage) {
        window.history.pushState({ changeMessageType: true }, null)
        resetFilters()
      } else {
        if (user.isSupportAgent) {
          setMessageType(MY_MESSAGES)
        } else {
          setMessageType(ALL)
        }
      }
    }
  }

  const markAsReadConversationFromList = (convList) => {
    const updatedData = listConversation.map((item) => {
      if (convList.includes(item._id)) {
        selectedConversation.is_unread = false
        return { ...item, is_unread: false }
      }
      return item
    })
    setSelectedConversation(selectedConversation)
    saveSelectedConversation(selectedConversation)
    setListConversation(updatedData)
  }

  const refreshConfersationList = (conversation, withisNewConversation = false) => {
    let newList
    if (!withisNewConversation) {
      newList = listConversation.filter((element) => !element.isNew)
    }
    newList = (newList || listConversation).map((element) =>
      element._id === conversation._id
        ? {
            ...conversation,
            superhumanReply:
              conversation?.superhumanReply || element?.superhumanReply,
          }
        : element
    )
    const newListSorted = sortingConversation(newList)
    setListConversation(newListSorted)
  }

  const refreshConversation = (
    conv,
    toSelect = true,
    scroll = true,
    toSelectAndSaveConv = true,
    isWithNewConversation = false
  ) => {
    if (!conv) return
    if (!conv.temperature) {
      conv.temperature = selectedConversation.temperature
    }
    if (toSelectAndSaveConv) {
      setSelectedConversation(conv)
      saveSelectedConversation(conv)
    }

    if (toSelect) {
      reorderToTop(conv)
      if (scroll) {
        if (!shouldStayAtSamePlace(conv)) {
          scrollToTop()
        }
      }
    } else {
      refreshConfersationList(conv, isWithNewConversation)
    }
  }

  const refreshReceiverOrTagsOnConversation = (
    prop,
    value,
    isWithNewConversation = false
  ) => {
    if (selectedConversation) {
      if (!Boolean(value.email)) {
        setMsgChangeEmailSMSType(PHONE)
      }
      const newConversation = _.cloneDeep(selectedConversation)
      newConversation[prop] = value
      refreshConversation(newConversation, false, true, true, isWithNewConversation)
    }
  }

  const scrollToTop = () => scrollConversation.current.scrollTo(0, 0)

  const finedScrollToTop = (index = 0) => {
    const scrollHeight =
      scrollConversation &&
      scrollConversation.current &&
      scrollConversation.current.scrollHeight
    const clientHeight =
      scrollConversation &&
      scrollConversation.current &&
      scrollConversation.current.clientHeight
    const newIndex =
      index > 40
        ? Math.round(index / (PER_PAGE / (scrollHeight - clientHeight))) +
          clientHeight / 1.5
        : Math.round(index / (PER_PAGE / (scrollHeight - clientHeight)))
    scrollConversation &&
      scrollConversation.current &&
      scrollConversation.current.scrollTo(0, newIndex)
  }

  const getConversationFromNotification = async (data) => {
    if (!data._organization_id || !data._conversation_id) {
      return
    }
    if (organization?._id !== data._organization_id) {
      const resultOrg = await userActions.changeOrgByAdmin(
        user?._id,
        data._organization_id
      )
      if (!resultOrg.success) {
        notifyError(resultOrg.errMsg)
        return
      }
      await authActions.setOrganization(resultOrg.data.user.organization)
    }

    const result = await messageActions.getConversationById(data._conversation_id)

    if (result.success) {
      setSelectedConversation(result.data.conversation[0])
      saveSelectedConversation(result.data.conversation[0])
      document.body.style.overflow = 'hidden'
      backswipeAction(
        '.message_center_panel-drawer',
        1,
        'center',
        setCenterBoxVisible,
        true
      )
      navigate(`/messages?type=${messageType}&center=true`)
    } else {
      notifyError(result.errMsg)
    }
  }

  useEffect(() => {
    if (unreadOnly && selectedConversation && selectedConversation.is_unread) {
      const readMsg = listConversation.filter(
        (item) => item.is_unread && selectedConversation.is_unread
      )
      setListConversation(readMsg)
    }
    if (
      messageType === UNASSIGNED &&
      selectedConversation &&
      selectedConversation.unassigned
    ) {
      const unassignedMsg = listConversation.filter(
        (item) => item.unassigned && selectedConversation.unassigned
      )
      setListConversation(unassignedMsg)
    }
    if (
      needsAttention &&
      selectedConversation &&
      selectedConversation.recent_tagged_users &&
      selectedConversation.recent_tagged_users.length
    ) {
      const readMsg = listConversation.filter(
        (item) =>
          item.recent_tagged_users.length &&
          selectedConversation.recent_tagged_users.length
      )
      setListConversation(readMsg)
    }
  }, [selectedConversation])

  const MessageContent = useMemo(() => {
    return (
      <MessageContext.Provider
        value={{
          conversation: selectedConversation,
          smartBox: smartBox,
          refreshConversation: refreshConversation,
          refreshConfersationList: refreshConfersationList,
          isExtension: isExtension,
          messageInputRef: messageInputRef,
          rightBoxVisible: rightBoxVisible,
          centerBoxVisible: centerBoxVisible,
        }}
      >
        <div className='message_main_wrapper'>
          <MessageLeftPanel
            listRef={listRef}
            listConversation={listConversation}
            setListConversation={setListConversation}
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            saveSelectedConversation={saveSelectedConversation}
            selectMultipleConversationsType={selectMultipleConversationsType}
            setselectMultipleConversationsType={setselectMultipleConversationsType}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            unreadOnly={unreadOnly}
            setUnreadOnly={setUnreadOnly}
            smartBox={smartBox}
            setSmartBox={setSmartBox}
            paginationOptionsForAPI={paginationOptionsForAPI}
            messageType={messageType}
            setMessageType={setMessageType}
            setSearchKeyword={setSearchKeyword}
            isFilteredMessage={isFilteredMessage}
            countOfFilter={countOfFilter}
            filterToggleStatuses={filterToggleStatuses}
            setFilterToggleStatuses={setFilterToggleStatuses}
            totalCount={totalCount}
            isExtension={isExtension}
            needsAttention={needsAttention}
            setNeedsAttention={setNeedsAttention}
            scrollConversation={scrollConversation}
            filterConversationsBySearchTerm={filterConversationsBySearchTerm}
            onLoadMore={() => {
              if (!isLoading) {
                fill(listConversation, page + 1, selectedConversation)
                setIsLoading(true)
              }
            }}
            MessageCenterRef={MessageCenterRef}
            setMsgChangeEmailSMSType={setMsgChangeEmailSMSType}
            setCenterBoxVisible={setCenterBoxVisible}
            backswipeAction={backswipeAction}
            bsClassNamesArray={bsClassNamesArray}
            refreshConversation={refreshConversation}
            searchBoxRef={searchBoxRef}
          />
          <section className='message_center_panel'>
            {selectedConversation ? (
              isTabletPortraitOrMobile ? (
                <Drawer
                  placement={'right'}
                  closable={true}
                  header={null}
                  push={false}
                  closeIcon={<SVGArrowLeft />}
                  onClose={() => {
                    document.body.style.overflow = ''
                    setCenterBoxVisible(false)
                  }}
                  open={centerBoxVisible}
                  width={'100%'}
                  rootClassName={`message_center_panel-drawer ${
                    isMobileApp ? 'message_center_panel-drawer-for-mobile' : ''
                  }`}
                  destroyOnClose={Boolean(isExtension)}
                >
                  <section className='message_center_panel'>
                    <MessageCenter
                      showRightBox={() => {
                        if (isMobileApp) {
                          navigate(`/messages?type=${messageType}&right=true`)
                          backswipeAction(
                            '.message_right_panel-drawer',
                            1,
                            'right',
                            null,
                            null
                          )
                        }
                        setRightBoxVisible(true)
                      }}
                      messageType={messageType}
                      conversation={selectedConversation}
                      disabled={messageType === BROADCAST}
                      refreshTags={(value) =>
                        refreshReceiverOrTagsOnConversation('tags_items', value)
                      }
                      refreshConversation={refreshConversation}
                      ref={MessageCenterRef}
                      reorderToTop={reorderToTop}
                      removeConversationFromList={removeConversationFromList}
                      changeMessageType={(type) => setMessageType(type)}
                      onChangeEmailSMSType={(type) => setMsgChangeEmailSMSType(type)}
                      msgChangeEmailSMSType={msgChangeEmailSMSType}
                      closeCenterDrawer={() => {
                        if (isMobileApp) {
                          myWebViewPost(
                            {
                              closedByArrow: true,
                            },
                            'CLOSE_MESSAGE_CENTER'
                          )
                        }
                        document.body.style.overflow = ''
                        setCenterBoxVisible(false)
                      }}
                      isExtension={isExtension}
                    />
                  </section>
                </Drawer>
              ) : (
                <MessageCenter
                  changeMessageType={(type) => setMessageType(type)}
                  showRightBox={() => setRightBoxVisible(true)}
                  messageType={messageType}
                  conversation={selectedConversation}
                  disabled={messageType === BROADCAST}
                  refreshTags={(value) =>
                    refreshReceiverOrTagsOnConversation('tags_items', value)
                  }
                  refreshConversation={refreshConversation}
                  ref={MessageCenterRef}
                  reorderToTop={reorderToTop}
                  removeConversationFromList={removeConversationFromList}
                  refreshConfersationList={refreshConfersationList}
                  onChangeEmailSMSType={(type) => setMsgChangeEmailSMSType(type)}
                  msgChangeEmailSMSType={msgChangeEmailSMSType}
                  isExtension={isExtension}
                />
              )
            ) : isMobileApp ? (
              <div className='empty-mobile-conversation'>
                <SVGEmptyConversationMobile />
                <p>Select a conversation to start messaging</p>
              </div>
            ) : null}
          </section>
          {selectedConversation && isDesktop ? (
            <section className='message_right_panel'>
              <MessageRight
                conversation={selectedConversation}
                disabled={messageType === BROADCAST}
                onSaveReciver={(value) =>
                  refreshReceiverOrTagsOnConversation('receiver', value, true)
                }
                onSaveTags={(value) =>
                  refreshReceiverOrTagsOnConversation('tags_items', value)
                }
                refreshConversation={refreshConversation}
                removeConversationFromList={removeConversationFromList}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
                isAwaitingConsent={
                  //  isLastMessageConsent ||
                  selectedConversation?.receiver?.status?.isWaitingAnswerToConsent
                }
              />
            </section>
          ) : (
            <Drawer
              placement='right'
              closable={false}
              onClose={() => setRightBoxVisible(false)}
              open={rightBoxVisible}
              width={isMobile ? '100%' : 312}
              rootClassName='message_right_panel-drawer'
              destroyOnClose={Boolean(isExtension)}
            >
              <section className='message_right_panel'>
                <MessageRight
                  conversation={selectedConversation}
                  disabled={messageType === BROADCAST}
                  onSaveReciver={(value) =>
                    refreshReceiverOrTagsOnConversation('receiver', value, true)
                  }
                  onSaveTags={(value) =>
                    refreshReceiverOrTagsOnConversation('tags_items', value)
                  }
                  refreshConversation={refreshConversation}
                  msgChangeEmailSMSType={msgChangeEmailSMSType}
                  isAwaitingConsent={
                    //  isLastMessageConsent ||
                    selectedConversation?.receiver?.status?.isWaitingAnswerToConsent
                  }
                  onCloseDrawer={() => {
                    if (isMobileApp) {
                      myWebViewPost(
                        {
                          shouldBackswipe: 'closedByX',
                        },
                        'SHOW_MESSAGE_CENTER'
                      )
                    }
                    setRightBoxVisible(false)
                  }}
                />
              </section>
            </Drawer>
          )}
        </div>
      </MessageContext.Provider>
    )
  }, [
    selectedConversation,
    messageType,
    listConversation,
    isLoading,
    rightBoxVisible,
    centerBoxVisible,
    menuOpen,
    msgChangeEmailSMSType,
    organization,
    filterToggleStatuses,
    selectMultipleConversationsType,
    isTabletPortraitOrMobile,
    isMobile,
    isDesktop,
  ])

  return MessageContent
})

export default MessageMain
